$icon-add-squared: "\e900"
$icon-add: "\e901"
$icon-archive-unpack: "\e902"
$icon-archive: "\e903"
$icon-arrow-down-hollow: "\e904"
$icon-arrow-left: "\e905"
$icon-arrow-right-hollow: "\e906"
$icon-arrow-right-squared: "\e907"
$icon-bell-crossed-filled: "\e908"
$icon-bell: "\e909"
$icon-book: "\e90a"
$icon-burger-menu: "\e90b"
$icon-cancel-circled: "\e90c"
$icon-cancel: "\e90d"
$icon-chats: "\e90e"
$icon-clock: "\e90f"
$icon-cloud-done: "\e910"
$icon-cloud-download: "\e911"
$icon-cloud-empty: "\e912"
$icon-connected: "\e913"
$icon-crown-filled: "\e914"
$icon-crown-hollow: "\e915"
$icon-discussion-add: "\e916"
$icon-discussion-attachment: "\e917"
$icon-discussion-message: "\e918"
$icon-discussion: "\e919"
$icon-document-add: "\e91a"
$icon-document: "\e91b"
$icon-done: "\e91c"
$icon-dropdown-arrow-filled: "\e91d"
$icon-dropdown-arrow: "\e91e"
$icon-earth: "\e91f"
$icon-email: "\e920"
$icon-eye-crossed: "\e921"
$icon-eye: "\e922"
$icon-flash: "\e923"
$icon-folder: "\e924"
$icon-gear: "\e925"
$icon-hamburger: "\e926"
$icon-heartbeat: "\e927"
$icon-home-filled: "\e928"
$icon-home-hollow: "\e929"
$icon-info: "\e92a"
$icon-link: "\e92b"
$icon-list-numbered: "\e92c"
$icon-lock: "\e92d"
$icon-mention: "\e92e"
$icon-message: "\e92f"
$icon-navigate-before: "\e930"
$icon-navigate-next:  "\e931"
$icon-notification: "\e932"
$icon-paperclip: "\e933"
$icon-pencil-underlined: "\e934"
$icon-pencil:  "\e935"
$icon-print: "\e936"
$icon-question-circled-filled: "\e937"
$icon-question-circled-hollow: "\e938"
$icon-question: "\e939"
$icon-quote: "\e93a"
$icon-reply: "\e93b"
$icon-screen-share: "\e93c"
$icon-search:  "\e93d"
$icon-share: "\e93e"
$icon-shared: "\e93f"
$icon-star-filled: "\e940"
$icon-star-hollow: "\e941"
$icon-tridot: "\e942"
$icon-upload: "\e943"
$icon-user-filled:  "\e944"
$icon-user-group: "\e945"
$icon-user-hollow: "\e946"
$icon-user-supervisor: "\e947"
$icon-user: "\e948"
$icon-vote: "\e949"
$icon-annotation: "\e94a"
$icon-agenda: "\e94b"
$icon-complete: "\e94c"
$icon-todo: "\e94d"
$icon-in-progress: "\e94e"
$icon-no-status: "\e94f"
$icon-checkmark: "\e950"
$icon-sign: "\e951"
$icon-collaborative-document: "\e952"
$icon-comment: "\e953"
$icon-share-and-arrow-up: "\e954"
$icon-download: "\e955"
$icon-block: "\e956"
$icon-today: "\e957"
$icon-schedule: "\e958"
$icon-pdf-download: "\e959"
$icon-pdf-document-download: "\e95a"
$icon-agenda-download: "\e95b"
$icon-openai: "\e95c"
$icon-signed: "\e95d"
$icon-pencil-plus: "\e95e"
$icon-copy: "\e95f"
$icon-folder-plus: "\e960"
$icon-upload-file: "\e961"
$icon-folder-upload: "\e962"
$icon-trash: "\e963"
$icon-exchange: "\e964"
$icon-arrow-right-top: "\e965"
$icon-message-v2: "\e966"
$icon-mention-v2: "\e967"
$icon-calendar: "\e968"
$icon-arrow-left-circled: "\e969"
$icon-arrow-right-circled: "\e96a"
$icon-star-hollow-v2: "\e96b"
$icon-star-filled-v2: "\e96c"
$icon-ai-summarise: "\e96d"
$icon-ai-query: "\e96e"
$icon-caret-up-slim: "\e96f"


@font-face
  font-family: 'knowa-icons'
  src: url("../../fonts/knowa-icons.ttf") format("truetype"), url("../../fonts/knowa-icons.woff") format("woff"), url("../../fonts/knowa-icons.svg#knowa-icons") format("svg")
  font-weight: normal
  font-style: normal

[class^="icon-"], [class*=" icon-"], %icon
  // use !important to prevent issues with browser extensions that change fonts
  font-family: 'knowa-icons' !important
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1
  // Better Font Rendering ===========
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icon-add-squared
  &:before
    content: $icon-add-squared

.icon-add
  &:before
    content: $icon-add

.icon-archive-unpack
  &:before
    content: $icon-archive-unpack

.icon-archive
  &:before
    content: $icon-archive

.icon-arrow-down-hollow
  &:before
    content: $icon-arrow-down-hollow

.icon-arrow-left
  &:before
    content: $icon-arrow-left

.icon-arrow-right-hollow
  &:before
    content: $icon-arrow-right-hollow

.icon-arrow-right-squared
  &:before
    content: $icon-arrow-right-squared

.icon-bell-crossed-filled
  &:before
    content: $icon-bell-crossed-filled

// TODO: check possible usage
.icon-bell
  &:before
    content: $icon-bell

.icon-book
  &:before
    content: $icon-book

.icon-burger-menu
  &:before
    content: $icon-burger-menu

.icon-cancel-circled
  &:before
    content: $icon-cancel-circled

.icon-cancel
  &:before
    content: $icon-cancel

.icon-chats
  &:before
    content: $icon-chats

.icon-clock
  &:before
    content: $icon-clock

.icon-cloud-done
  &:before
    content: $icon-cloud-done

.icon-cloud-download
  &:before
    content: $icon-cloud-download

.icon-cloud-empty
  &:before
    content: $icon-cloud-empty

.icon-connected
  &:before
    content: $icon-connected

.icon-crown-filled
  &:before
    content: $icon-crown-filled

// TODO: check possible usage
.icon-crown-hollow
  &:before
    content: $icon-crown-hollow

.icon-discussion-add
  &:before
    content: $icon-discussion-add

// TODO: check possible usage
.icon-discussion-attachment
  &:before
    content: $icon-discussion-attachment

.icon-discussion-message
  &:before
    content: $icon-discussion-message

.icon-discussion
  &:before
    content: $icon-discussion

.icon-document-add
  &:before
    content: $icon-document-add

.icon-document
  &:before
    content: $icon-document

.icon-done
  &:before
    content: $icon-done

.icon-dropdown-arrow-filled
  &:before
    content: $icon-dropdown-arrow-filled

.icon-dropdown-arrow
  &:before
    content: $icon-dropdown-arrow

// TODO: check possible usage
.icon-earth
  &:before
    content: $icon-earth

.icon-email
  &:before
    content: $icon-email

.icon-eye-crossed
  &:before
    content: $icon-eye-crossed

.icon-eye
  &:before
    content: $icon-eye

// TODO: check possible usage
.icon-flash
  &:before
    content: $icon-flash

.icon-folder
  &:before
    content: $icon-folder

.icon-gear
  &:before
    content: $icon-gear

.icon-hamburger
  &:before
    content: $icon-hamburger

// TODO: check possible usage
.icon-heartbeat
  &:before
    content: $icon-heartbeat

.icon-home-filled
  &:before
    content: $icon-home-filled
    bottom: .07em
    font-size: .9em
    position: relative

.icon-home-hollow
  &:before
    content: $icon-home-hollow
    bottom: .07em
    font-size: .9em
    position: relative

.icon-info
  &:before
    content: $icon-info

.icon-link
  &:before
    content: $icon-link

.icon-list-numbered
  &:before
    content: $icon-list-numbered

.icon-lock
  &:before
    content: $icon-lock

.icon-mention
  &:before
    content: $icon-mention

.icon-message
  &:before
    content: $icon-message

.icon-navigate-before
  &:before
    content: $icon-navigate-before

.icon-navigate-next
  &:before
    content: $icon-navigate-next

// TODO: check possible usage
.icon-notification
  &:before
    content: $icon-notification

.icon-paperclip
  &:before
    content: $icon-paperclip

// TODO: check possible usage
.icon-pencil-underlined
  &:before
    content: $icon-pencil-underlined

.icon-pencil
  &:before
    content: $icon-pencil

.icon-print
  &:before
    content: $icon-print

.icon-question-circled-filled
  &::before
    content: $icon-question-circled-filled

// TODO: check possible usage
.icon-question-circled-hollow
  &::before
    content: $icon-question-circled-hollow

// TODO: check possible usage
.icon-question
  &::before
    content: $icon-question

.icon-quote
  &:before
    content: $icon-quote

.icon-reply
  &:before
    content: $icon-reply

.icon-screen-share
  &:before
    content: $icon-screen-share

.icon-search
  &:before
    content: $icon-search

.icon-share
  &:before
    content: $icon-share

// TODO: check possible usage
.icon-shared
  &:before
    content: $icon-shared

.icon-star-filled
  &:before
    content: $icon-star-filled
    bottom: .07em
    font-size: .9em
    position: relative

.icon-star-hollow
  &:before
    content: $icon-star-hollow
    bottom: .07em
    font-size: .9em
    position: relative

.icon-tridot
  &:before
    content: $icon-tridot

.icon-upload
  &:before
    content: $icon-upload

// TODO: check possible usage
.icon-user-filled
  &:before
    content: $icon-user-filled

.icon-user-group
  &:before
    content: $icon-user-group

.icon-user-hollow
  &:before
    content: $icon-user-hollow

.icon-user-supervisor
  &:before
    content: $icon-user-supervisor

.icon-user
  &:before
    content: $icon-user

.icon-vote
  &:before
    content: $icon-vote

.icon-annotation
  &:before
    content: $icon-annotation

.icon-agenda
  &:before
    content: $icon-agenda

.icon-no-status
  &:before
    content: $icon-no-status
    font-size: .9em
    color: white
    border: 1px solid #DDDFE5
    border-radius: 50%

.icon-todo
  color: #E97B7B
  &:before
    content: $icon-todo

.icon-in-progress
  color: #F8CB53
  &:before
    content: $icon-in-progress

.icon-complete
  &:before
    content: $icon-complete
    color: #99d136
  &:after
    content: $icon-checkmark
    color: #fff
    margin-left: -1em

.icon-sign
  &:before
    content: $icon-sign

.icon-collaborative-document
  &:before
    content: $icon-collaborative-document

.icon-comment
  &:before
    content: $icon-comment

.icon-share-and-arrow-up
  &:before
    content: $icon-share-and-arrow-up

.icon-download
  &:before
    content: $icon-download

.icon-block
  &:before
    content: $icon-block

.icon-today
  &:before
    content: $icon-today

.icon-schedule
  &:before
    content: $icon-schedule

.icon-agenda-download
  &:before
    content: $icon-agenda-download

.icon-pdf-download
  &:before
    content: $icon-pdf-download

.icon-pdf-document-download
  &:before
    content: $icon-pdf-document-download

.icon-openai
  &:before
    content: $icon-openai

.icon-signed
  &:before
    content: $icon-signed

.icon-pencil-plus
  &:before
    content: $icon-pencil-plus

.icon-copy
  &:before
    content: $icon-copy

.icon-folder-plus
  &:before
    content: $icon-folder-plus

.icon-upload-file
  &:before
    content: $icon-upload-file

.icon-folder-upload
  &:before
    content: $icon-folder-upload

.icon-trash
  &:before
    content: $icon-trash

.icon-exchange
  &:before
    content: $icon-exchange

.icon-arrow-right-top
  &:before
    content: $icon-arrow-right-top

.icon-message-v2
  &:before
    content: $icon-message-v2

.icon-mention-v2
  &:before
    content: $icon-mention-v2

.icon-calendar
  &:before
    content: $icon-calendar

.icon-arrow-left-circled
  &:before
    content: $icon-arrow-left-circled

.icon-arrow-right-circled
  &:before
    content: $icon-arrow-right-circled

.icon-star-hollow-v2
  &:before
    content: $icon-star-hollow-v2

.icon-star-filled-v2
  &:before
    content: $icon-star-filled-v2

.icon-ai-query
  &:before
    content: $icon-ai-query

.icon-ai-summarise
  &:before
    content: $icon-ai-summarise

.icon-caret-up-slim
  &:before
    content: $icon-caret-up-slim
